import React from 'react';

const TwoKProgrammeRecovery = ({ queryParams }) => {
    
    const restingHeartRate = queryParams.get('restingHeartRate');
    
    return (
        <div className="c-programme__recovery xs-mt-40 xs-pt-30 xs-pb-30 lg-pt-40 lg-pb-40">
            <div className="row">
                <div className="col-md-8 offset-md-2 col-10 offset-1">
                    <h4 className="xs-mb-20">Recovery Time Between Intervals</h4>

                    <p>Full recovery between intervals can be considered as taken place when the heart rate has fallen to warm up level (twice resting rate). The intensity of interval-training can be increased by working to 90% or even 80% of full recovery.</p>
                    
                    <ul className="c-programme__recovery-list">
                        <li>Resting heart rate = {restingHeartRate} BPM</li>
                        <li>Warm up = {restingHeartRate * 2} BPM</li>
                        <li>Full recovery = {restingHeartRate * 2} BPM</li>
                        <li>90% recovery = {Math.round(parseInt(restingHeartRate) + (restingHeartRate * 1.1))} BPM</li>
                        <li>80% recovery = {Math.round(parseInt(restingHeartRate) + (restingHeartRate * 1.2))} BPM</li>
                    </ul>
                    
                    
                    <p>The closer the recovery is towards 100%, the more the training will tend towards improving Power, while the closer the recovery is towards 80%, the more the training will tend towards improving lactate tolerance. Reduced recovery is most effective at the beginning of an intensive interval-training period when intensity takes precedence over quality. Close to competition quality takes precedence over intensity and therefore full recovery is advisable.</p>
                    
                    <p class="xs-pb-0"><span className="c-programme__recovery-important">IMPORTANT:</span> Medical Recommendation. If on race day you feel unwell, or have recently been suffering from a virus, we recommend that you do not race. Indoor racing requires maximal effort and WINTECH Racing takes no responsibility for illness or injury caused as a consequence. If you have any doubts you should seek medical advice prior to racing.</p>
                    
                </div>
            </div>
        </div>
    );
}

export default TwoKProgrammeRecovery;
