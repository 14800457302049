import React, {useState} from 'react';
import {Link} from "@reach/router";
import Cookies from "js-cookie";

const CookieNotice = () => {
    const [cookieVisible, setCookieVisible] = useState(null);
    
    if (Cookies.get('cookie-notice') === undefined && cookieVisible !== true) {
        setCookieVisible(true);
    }
    
    function handleClose() {
        Cookies.set('cookie-notice', true, { expires: 365 });
        setCookieVisible(false);
    }
    
    if (cookieVisible) {
        return (
            <div className="c-cookie-notice">
                <div className="container">
                    <div className="c-cookie-notice__details">
                    <span className="c-cookie-notice__description">
                        <span>We use cookies to elevate your user experience and enhance the quality of our site. </span>
                        <Link className="u-text--underline" to="/terms-and-conditions/">To find out more view our privacy policy.</Link>
                    </span>
                        <button onClick={() => handleClose()} className="c-button c-button--close c-cookie-notice__btn" aria-label="Accept and close">
                            <i className="fal fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <>
            </>
        );
    }
};

export default CookieNotice;
