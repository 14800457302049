import React from 'react';
import { graphql } from 'gatsby';
import { navigate } from "gatsby"

import Layout from '../components/component.layout';
import SEO from '../components/component.seo';
import HeroBanner from '../components/hero-banner/component.banner';
import CookieNotice from "../components/component.cookie-notice";
import Programme from "../components/programme/component.programme";

const ProgrammeTemplate = ({ data, location }) => {
    const { title, content, blocks, featuredImage, seo, ACFFeaturedVideoBlockFields: video } = data.wordpress.programme;
    const bannerImage = featuredImage ? featuredImage.node.imageFile : false;
    
    // prevent users from accessing the form without adding their email to the newsletter form on the previous page
    // ignore this if the URL has query params for the results
    const queryParams = typeof window !== `undefined` ? new URLSearchParams(window.location.search) : false;
    if (!location.state && queryParams && !queryParams.has('level')) {
        navigate('/training-programmes');
    }
    
    return (
        <Layout>
            <SEO title={title} seo={seo} image={featuredImage} />
            <HeroBanner title="Training" image={bannerImage} video={video} />
            
            <Programme programme={data.wordpress.programme} content={content} blocks={blocks} location={location} />
            
            <CookieNotice />
        </Layout>
    );
};

export const query = graphql`
    query($id: ID!) {
        wordpress {
            programme(id: $id) {
                title
                slug
                content
                featuredImage {
                    node {
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                                fluid(maxWidth: 1920) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
                ACFProgrammeFields {
                    description
                }
                seo {
                    ...SeoPostFields
                }
                ...ProgrammeBlocks
            }
        }
    }
`;

export default ProgrammeTemplate;
