import React, {useEffect, useState} from 'react';
import {Link} from "gatsby";
import Button from "../component.button";
import useFetchPost from "../../hooks/hook.useFetchPost";

const TwoKProgrammeSend = ({ programme }) => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const defaultFormDetails = {
        contactName: '',
        contactEmail: '',
        link: window.location.href,
        programme: programme.title
        
    };
    const [formDetails, setFormDetails] = useState(defaultFormDetails);
    
    const handleInputChange = event => {
        const { target } = event;
        const { value, name } = target;
        
        setFormDetails({
            ...formDetails,
            [name]: value,
        });
    };
    
    const formSubmission = useFetchPost({
        path: '/wp-json/contact-form-7/v1/contact-forms/1918/feedback',
        data: formDetails,
        submitClick: formSubmitted
    });
    
    const handleSubmit = async event => {
        event.preventDefault();
        
        // submit the form
        await setFormSubmitted(true);
        // revert the form submitted to default state;
        await setFormSubmitted(false);
    };
    
    useEffect(() => {
        // clear the form if it's successful
        if (formSubmission[0].success === true) {
            formSubmission[0].success = false;
            setFormDetails(defaultFormDetails);
        }
    }, [formSubmitted, formSubmission]);
    
    return (
        <div className="c-programme__send xs-mt-40 xs-pt-30 xs-pb-30 lg-pt-40 lg-pb-40">
            <div className="row">
                <div className="col-md-8 offset-md-2 col-10 offset-1">
                    
                    <p>Leave us your email address and we'll send you a link to your training programme so you can refer to it in future.</p>
    
                    <form onSubmit={handleSubmit}>
        
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="c-input-holder">
                                    <input
                                        className={formDetails.contactName.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                        type="text"
                                        id="contactName"
                                        name="contactName"
                                        value={formDetails.contactName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label className="c-input-holder__label" htmlFor="contactName">Name</label>
                                </div>
                            </div>
            
                            <div className="col-sm-6">
                                <div className="c-input-holder">
                                    <input
                                        className={formDetails.contactEmail.length ? "c-input-holder__input c-input-holder__input--has-content" : "c-input-holder__input"}
                                        type="text"
                                        id="contactEmail"
                                        name="contactEmail"
                                        value={formDetails.contactEmail}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    <label className="c-input-holder__label" htmlFor="contactEmail">Email</label>
                                </div>
                            </div>
                        </div>
        
                        <div className="row">
                            <div className="col-sm-6">
                                <Link className="u-text--extra-small o-link--underlined" to="/terms-and-conditions/">View our privacy policy</Link>
                            </div>
            
                            <div className="col-sm-6">
                                <div className="c-programme__submit">
                                    <Button text="Send My Programme" />
                                </div>
                            </div>
                        </div>
                    </form>
                
                </div>
            </div>
        </div>
    );
}

export default TwoKProgrammeSend;
