import { useState, useEffect, useContext } from 'react';

import ThemeContext from "../context/context.theme";

const useFetchPost = ({ path, submitClick, data }) => {
    const theme = useContext(ThemeContext);
    const [submitStatus, setSubmitStatus] = useState({
        success: false,
        loading: false,
        message: '',
    });

    const url = process.env.GATSBY_DOMAIN;

    async function fetchUrl() {
        const formData = new FormData();
        Object.keys(data).map(key => formData.append(key, data[key]));

        const response = await fetch(url + path, {
            method: "POST",
            body: formData,
        });

        await response.json().then((response) => {
            if (response.status === 'mail_sent') {
                setSubmitStatus({
                    success: true,
                    loading: false,
                });

                theme.setNotification({
                    message: response.message,
                    timestamp: Date(),
                    success: true
                });

            } else {
                let message = response.message;
                if (response.invalidFields.length) {
                    message = 'Please check the following fields: ' + response.invalidFields.map(o => o.idref).join(', ');
                }

                setSubmitStatus({
                    success: false,
                    loading: false,
                });

                theme.setNotification({
                    message,
                    timestamp: Date(),
                    success: false,
                });
            }
        })
            .catch((error) => {
                setSubmitStatus({
                    success: false,
                    loading: false,
                });

                theme.setNotification({
                    message: 'Something went wrong...',
                    timestamp: Date(),
                    success: false,
                });
            });
    }

    useEffect(() => {
        if (submitClick) {
            setSubmitStatus({ loading: true });
            fetchUrl();
        }
    }, [submitClick]);

    // tie global loading to fetch loading state
    theme.setLoading(submitStatus.loading);

    return [submitStatus, setSubmitStatus];
}

export default useFetchPost;
