import React from 'react';

const TwoKProgrammeKey = ({ items }) => {

    if (items && items.hasOwnProperty('athlete_level_key')) {
        return (
            <div className="c-programme__key xs-mt-40 xs-pt-30 xs-pb-30 lg-pt-40 lg-pb-40">
                <div className="row">
                    <div className="col-md-8 offset-md-2 col-10 offset-1">
                        
                        <h3 className="c-programme__key-title">Athlete Level Key</h3>
    
                        <div className="c-table xs-mt-20">
                            <div className="c-table__head c-table__head--border-bottom">
                                <div className="c-table__col c-table__col--sixth c-table__col--text-left c-table__col--text-red">Level</div>
                                <div className="c-table__col c-table__col--two-thirds c-table__col--text-left c-table__col--text-red">Description</div>
                                <div className="c-table__col c-table__col--sixth c-table__col--text-left c-table__col--text-red">Sessions</div>
                            </div>
                            {items['athlete_level_key'] && items['athlete_level_key'].map((item, key) => (
                                <div key={key} className="c-table__row c-table__row--border-bottom xs-pt-10 xs-pb-10">
                                    <div className="c-table__col c-table__col--sixth c-table__col--text-bold">{item.level}</div>
                                    <div className="c-table__col c-table__col--two-thirds">{item.description}</div>
                                    <div className="c-table__col c-table__col--sixth">
                                        {item.minimum_number_of_sessions === item.maximum_number_of_sessions ? item.minimum_number_of_sessions :
                                            `${item.minimum_number_of_sessions} - ${item.maximum_number_of_sessions}` }
                                    </div>
                                </div>
                            ))}
                        </div>
                        
                    </div>
                </div>
            </div>

        );
    }
    
    return (
        <></>
    );
}

export default TwoKProgrammeKey;
