import React from 'react';

const TwoKProgrammeNotes = () => {
    
    return (
        <div className="c-programme__notes u-bg--mercury xs-mt-40 xs-pt-30 xs-pb-30 lg-pt-40 lg-pb-40">
            <div className="row">
                <div className="col-md-8 offset-md-2 col-10 offset-1">
                    <h4 className="xs-mb-20">Notes for Training Table (examples)</h4>

                    <ol className="c-programme__notes-list xs-mb-40">
                        <li className="c-programme__notes-list-item">HRR% = Heart Rate Reserve (HRR) is the term used to describe the difference between a person's maximum (MHR) and resting heart rate (RHR). To calculate the training zones we use the following formula: Heart rate = (HRR * percentage) + RHR.</li>
                        <li className="c-programme__notes-list-item">30'UT2 means row for 30 minutes in your UT2 Range.</li>
                        <li className="c-programme__notes-list-item">2x15'UT1 means row for 15 minutes in your UT1 range, with a short rest of five or six minutes between each piece.</li>
                        <li className="c-programme__notes-list-item">8x1'AN means row eight one-minute intervals in your AN range, with a one minute rest between each piece.</li>
                        <li className="c-programme__notes-list-item">6+5'AT means a six minute piece followed by a five minute piece at your AT range, with a six minute rest between pieces.</li>
                        <li className="c-programme__notes-list-item">3x3'TR means three lots of three minutes at your TR range.</li>
                        <li className="c-programme__notes-list-item">TEST means rowing 2,000 metres as fast as possible, to enable you to benchmark your progress.</li>
                    </ol>
                    
                    <div className="c-table c-table--bordered xs-mb-40">
                        <div className="c-table__head">
                            <div className="c-table__col c-table__col--third  c-table__col--text-left c-table__col--black">Type of session</div>
                            <div className="c-table__col c-table__col--third  c-table__col--text-left c-table__col--black">Warm up</div>
                            <div className="c-table__col c-table__col--third  c-table__col--text-left c-table__col--black">Cool down</div>
                        </div>
                        <div className="c-table__row">
                            <div className="c-table__col c-table__col--third c-table__col--white">UT2</div>
                            <div className="c-table__col c-table__col--third c-table__col--white">5-8 min</div>
                            <div className="c-table__col c-table__col--third c-table__col--white">5-8 min</div>
                        </div>
                        <div className="c-table__row">
                            <div className="c-table__col c-table__col--third c-table__col--white">UT1</div>
                            <div className="c-table__col c-table__col--third c-table__col--white">8-10 min</div>
                            <div className="c-table__col c-table__col--third c-table__col--white">8-10 min</div>
                        </div>
                        <div className="c-table__row">
                            <div className="c-table__col c-table__col--third c-table__col--white">AT</div>
                            <div className="c-table__col c-table__col--third c-table__col--white">10-12 min</div>
                            <div className="c-table__col c-table__col--third c-table__col--white">10-12 min</div>
                        </div>
                        <div className="c-table__row">
                            <div className="c-table__col c-table__col--third c-table__col--white">TR</div>
                            <div className="c-table__col c-table__col--third c-table__col--white">12-15 min</div>
                            <div className="c-table__col c-table__col--third c-table__col--white">12-15 min</div>
                        </div>
                        <div className="c-table__row">
                            <div className="c-table__col c-table__col--third c-table__col--white">AN</div>
                            <div className="c-table__col c-table__col--third c-table__col--white">15-20 min</div>
                            <div className="c-table__col c-table__col--third c-table__col--white">15-20 min</div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default TwoKProgrammeNotes;
